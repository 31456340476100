import React, { useState } from "react";
import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";
import axios from "axios";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const ContractorPortalBiddingJobs = ({ open, setOpen, singleJob }) => {
  const [loading, setLoading] = useState(false);
  const { currentUser } = useSelector((state) => state.user);
  const [formList, setFormList] = useState({
    contractorName: currentUser?.username,
    contractorEmail: currentUser?.email,
    contractorPhone: currentUser?.phoneNo,
    contractorOccupation: "",
    contractorChatUserId: "",
    desc: "",
    BiddingAmount: "",
  });
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormList({ ...formList, [name]: value });
  };

  const handleBidForJob = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await axios.put(
        `https://nuova-property-server.onrender.com/api/biddings/${singleJob?.jobid}`,
        formList
      );
      if (res.data) {
        setLoading(false);
        Swal.fire("Success!", "You successfully bid for the job!", "success");
        window.location.reload(false);
      }
    } catch (err) {
      setLoading(false);
      Swal.fire(
        "Error",
        "Some fields are missing or invalid. Please check your input and try again.",
        "error"
      );
      console.log(err);
    }
  };
  const cancelButtonRef = useRef(null);
  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                      <CheckIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Bid for the job
                      </Dialog.Title>

                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900 mt-4"
                      >
                        {singleJob?.issueName}
                      </Dialog.Title>
                    </div>
                    <form>
                      <div className="mt-2">
                        <div className=" grid grid-cols-2 gap-5 pt-5">
                          <div className="col-span-1">
                            <label
                              htmlFor="contractorName"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Contractor Name
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="contractorName"
                                id="contractorName"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
                                defaultValue={currentUser?.username}
                                onChange={(e) => {
                                  handleFormChange(e);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-span-1">
                            <label
                              htmlFor="contractorEmail"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Contractor Email
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="contractorEmail"
                                id="contractorEmail"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
                                defaultValue={currentUser?.email}
                                onChange={(e) => {
                                  handleFormChange(e);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-span-1">
                            <label
                              htmlFor="contractorEmail"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Contractor Phone
                            </label>
                            <div className="mt-1">
                              <input
                                type="Number"
                                name="contractorPhone"
                                id="contractorPhone"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
                                defaultValue={currentUser?.phoneNo}
                                onChange={(e) => {
                                  handleFormChange(e);
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-span-1">
                            <label
                              htmlFor="contractorEmail"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Contractor Occupation{" "}
                              <span className="text-red-500 font-bold ">*</span>
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="contractorOccupation"
                                id="contractorOccupation"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
                                onChange={(e) => {
                                  handleFormChange(e);
                                }}
                                required
                              />
                            </div>
                          </div>

                          <div className="col-span-2">
                            <label
                              htmlFor="desc"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Describe the process of your Job Completion{" "}
                              <span className="text-red-500 font-bold ">*</span>
                            </label>
                            <div className="mt-1">
                              <textarea
                                type="text"
                                rows={4}
                                name="desc"
                                id="desc"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
                                onChange={(e) => {
                                  handleFormChange(e);
                                }}
                                required
                              />
                            </div>
                          </div>

                          <div className="col-span-1">
                            <label
                              htmlFor="BiddingAmount"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Bidding Amount{" "}
                              <span className="text-red-500 font-bold ">*</span>
                            </label>
                            <div className="mt-1">
                              <input
                                type="number"
                                name="BiddingAmount"
                                id="BiddingAmount"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm "
                                onChange={(e) => {
                                  handleFormChange(e);
                                }}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-span-1">
                            <label
                              htmlFor="BiddingAmount"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Contractor Chat User Id{" "}
                              <span className="text-red-500 font-bold ">*</span>
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="contractorChatUserId"
                                id="contractorChatUserId"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm "
                                onChange={(e) => {
                                  handleFormChange(e);
                                }}
                                required
                              />
                            </div>
                          </div>
                        </div>

                        <input
                          type="submit"
                          value={
                            loading
                              ? "Applying for the job"
                              : "Apply for the job"
                          }
                          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-cyan-600 px-4 py-2 text-base font-medium shadow-sm hover:bg-cyan-800  sm:col-start-1 sm:mt-4 sm:text-sm text-white cursor-pointer"
                          onClick={(e) => handleBidForJob(e)}
                        ></input>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default ContractorPortalBiddingJobs;
