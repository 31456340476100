import React from "react";
import ChatHome from "../../Chat/ChatHome/ChatHome";

const LandlordPortalInbox = () => {
  return (
    <>
      <ChatHome />
    </>
  );
};

export default LandlordPortalInbox;
