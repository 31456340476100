import React from 'react';
import ChatHome from '../../Chat/ChatHome/ChatHome';

const ContractorPortalInbox = () => {
    return (
        <>
            <ChatHome />
        </>
    );
};

export default ContractorPortalInbox;