import React from "react";
import ChatHome from "../../Chat/ChatHome/ChatHome";

const TenantPortalInbox = () => {
  return (
    <>
      <ChatHome />
    </>
  );
};

export default TenantPortalInbox;
