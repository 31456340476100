import React from "react";
import ChatHome from "../../../Chat/ChatHome/ChatHome";

const ManagerInbox = () => {
  return (
    <>
      <ChatHome />
    </>
  );
};

export default ManagerInbox;
