import React from "react";

const BankDetails = ({ register }) => {
  return (
    <>
      <h1 className="text-xl font-semibold leading-6 text-gray-900">
        3.b Bank Account Details
      </h1>

      <p className="text-lg leading-6 font-semibold text-gray-900">Owner 1</p>

      <div className="grid grid-cols-2 gap-5 pt-2">
        <div className="col-span-1">
          <label
            htmlFor="project-name"
            className="block text-sm font-medium text-gray-700"
          >
            Sort code
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="project-name"
              id="project-name"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
              {...register("ownershipDetails.bankAccount.ownerOne.sortCode")}
            />
          </div>
        </div>
        <div className="col-span-1">
          <label
            htmlFor="project-name"
            className="block text-sm font-medium text-gray-700"
          >
            Bank
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="project-name"
              id="project-name"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
              {...register("ownershipDetails.bankAccount.ownerOne.bank")}
            />
          </div>
        </div>
        <div className="col-span-1">
          <label
            htmlFor="project-name"
            className="block text-sm font-medium text-gray-700"
          >
            Account number
          </label>
          <div className="mt-1">
            <input
              type="number"
              name="project-name"
              id="project-name"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
              {...register("ownershipDetails.bankAccount.ownerOne.accountNo")}
            />
          </div>
        </div>
        <div className="col-span-1">
          <label
            htmlFor="project-name"
            className="block text-sm font-medium text-gray-700"
          >
            Account name
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="project-name"
              id="project-name"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
              {...register("ownershipDetails.bankAccount.ownerOne.accountName")}
            />
          </div>
        </div>
      </div>

      <hr className=" border-1 border-gray-400" />

      <p className="text-lg leading-6 font-semibold text-gray-900">Owner 2</p>

      <div className="grid grid-cols-2 gap-5 pt-2">
        <div className="col-span-1">
          <label
            htmlFor="project-name"
            className="block text-sm font-medium text-gray-700"
          >
            Sort code
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="project-name"
              id="project-name"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
              {...register("ownershipDetails.bankAccount.ownerTwo.sortCode")}
            />
          </div>
        </div>
        <div className="col-span-1">
          <label
            htmlFor="project-name"
            className="block text-sm font-medium text-gray-700"
          >
            Bank
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="project-name"
              id="project-name"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
              {...register("ownershipDetails.bankAccount.ownerTwo.bank")}
            />
          </div>
        </div>
        <div className="col-span-1">
          <label
            htmlFor="project-name"
            className="block text-sm font-medium text-gray-700"
          >
            Account number
          </label>
          <div className="mt-1">
            <input
              type="number"
              name="project-name"
              id="project-name"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
              {...register("ownershipDetails.bankAccount.ownerTwo.accountNo")}
            />
          </div>
        </div>
        <div className="col-span-1">
          <label
            htmlFor="project-name"
            className="block text-sm font-medium text-gray-700"
          >
            Account name
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="project-name"
              id="project-name"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
              {...register("ownershipDetails.bankAccount.ownerTwo.accountName")}
            />
          </div>
        </div>
      </div>

      <hr className=" border-1 border-gray-400" />

      <p className="text-base leading-6 font-normal text-gray-900">
        Please indicate the percentage split between each owner if there is more
        than one beneficiary.
      </p>

      <div className="grid grid-cols-2 gap-5 pt-2">
        <div className="col-span-1">
          <label
            htmlFor="project-name"
            className="block text-sm font-medium text-gray-700"
          >
            Owner 1(%)
          </label>
          <div className="mt-1">
            <input
              type="number"
              name="project-name"
              id="project-name"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
              {...register("ownershipDetails.bankAccount.percentage.ownerOne")}
            />
          </div>
        </div>
        <div className="col-span-1">
          <label
            htmlFor="project-name"
            className="block text-sm font-medium text-gray-700"
          >
            Owner 2(%)
          </label>
          <div className="mt-1">
            <input
              type="number"
              name="project-name"
              id="project-name"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
              {...register("ownershipDetails.bankAccount.percentage.ownerTwo")}
            />
          </div>
        </div>
      </div>

      <p className="text-base leading-6 text-gray-900 pt-3">
        If your payment is to be sent to a non-UK based bank account, please
        enter the details below. Please note, we will apply a £25 Overseas
        Payment charge for all payments sent to a non-UK based bank account.
      </p>

      <hr className=" border-1 border-gray-400" />
    </>
  );
};

export default BankDetails;
